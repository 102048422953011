@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"); */

@layer base {
  html {
    font-family: montserrat !important;
  }
}

:root {
  --theme-color: #000;
  --color-light: #8c8c8c;
  --line-color: #505050;
  --yellow-color: #ffab00;
  --grenn-color: #1df66a;
  --green-dard: #121c15;
  --green-dard-opt: #122c1c;
  --green-dard-grey: #1a241e;
  --green-medium: #08540f;
  --black-transparent: #000000c4;
}

@font-face {
  font-family: debug;
  src: url("../public/fonts/DEBUG.woff");
}

path.small-poly.active {
  fill: var(--grenn-color);
}

/* .loading {
  -webkit-animation: rotation 30s infinite linear;
  animation: rotation 30s infinite linear;
}
.loading:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
} */

.clip-pathcorner{
  clip-path: polygon(98.5% 0, 104% 50%, 100% 100%, 8% 150%, 0% 89%, 0 0%);
}


#navbar {
  transition: 250ms;
}
.top-80px {
  top: -80px !important;
}

input::file-selector-button {
  background-color: var(--grenn-color) !important;
  border: 0;
  font-weight: 600;
}
.orbitron-font {
  font-family: Orbitron;
}
.chakrapetch-font {
  font-family: "Chakra Petch", sans-serif;
}
.debug-font {
  font-family: debug;
}
.leading-hidden {
  line-height: 0.5;
}
.text-green {
  color: var(--grenn-color) !important;
}
.text-green-opt {
  color: var(--green-dard-opt) !important;
}
.bg-green {
  background-color: var(--grenn-color) !important;
}
.bg-theme {
  background-color: var(--theme-color);
}
.text-light {
  color: var(--color-light);
}
.border-color-grey {
  border-color: var(--line-color);
}
.border-color-yellow {
  border-color: var(--yellow-color);
}
.border-color-green {
  border-color: var(--grenn-color);
}
.border-color-green-medium {
  border-color: var(--green-medium);
}
.bg-gradient-light {
  background: linear-gradient(107.63deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
}
.grid-line {
  height: 1px;
  background: linear-gradient(45deg, #505050, #5050502b);
}
.text-yellow {
  color: var(--yellow-color);
}
.blur-1px {
  filter: blur(1.8px);
}

.min-h-264 {
  min-height: 264px;
}
.min-h-172 {
  min-height: 172px;
}
.md-h-274 {
  height: 274px;
}
.md-h-454 {
  height: 454px;
}
.min-h-384 {
  min-height: 384px;
}
.bg-black-transparent {
  background-color: var(--black-transparent);
}
.card-bg-gradient {
  background: linear-gradient(80deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}
.text-xxs {
  font-size: 10px;
}
.text-border {
  /* background: linear-gradient(0deg, rgb(29 246 106), rgb(29 246 106)),
    linear-gradient(
      250.62deg,
      #1df66a 0.95%,
      #1df66a 20.15%,
      rgb(29 246 106) 55.47%,
      rgb(29 246 106) 80.15%,
      rgb(29 246 106) 93.38%,
      rgb(29 246 106) 113.24%
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: #141414;
  background-clip: text; */
  /* text-shadow: 0 0 2px green; */
  -webkit-text-stroke: 0.75px #ffff;

  color: #141414;
  /* text-fill-color: #141414; */
}
.text-green-40per {
  color: #1f9648;
}
.filter-shadow-green {
  filter: grayscale(0) drop-shadow(0 0 13px green) !important;
}

.text-shadow-green {
  text-shadow: 0px 2px 5px var(--grenn-color);
}
.drop-section {
  background-image: url("../src/ui/assets/image/dropbg.png");
  background-size: auto;
  background-repeat: no-repeat;
}
.popupbg {
  background-image: url("../src/ui/assets/image/popupbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.btn-layout {
  background-image: url("../src/ui/assets/image/btn-bg.png");
  background-size: 100% 100%;
}
.img-div2 {
  background: url("../src/ui/assets/image/elitebg.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.export-btn-layout {
  background-image: url("../src/ui/assets/image/explore-btn.png");
  background-size: 100% 100%;
}

.auth-card {
  background-image: url("../src/ui/assets/image/Card.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.or-btn2 {
  background-image: url("../src/ui/assets/image/btn-bg2.svg");
  background-size: 100% 100%;
}
.or-btn {
  background-image: url("../src/ui/assets/image/orbtn.svg");
  background-size: 100% 100%;
}
.banner-bg {
  background-image: url("../src/ui/assets/image/bannerbg2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.extraBg {
  display: none !important;
}
@media screen and (max-width: 632px) {
  .banner-bg {
    background-position-y: 90px;
  }
  .extraBg {
    display: block !important;
    top: 378px;
    background-color: #1414144d;
    width: 100%;
  }
  .extraBg img {
    /* transform: rotate(35deg) translate(-25%, 25%); */
    position: absolute;
    top: -72px;
    width: 100%;
  }
}
.bg-p2e {
  background-image: url(./ui/assets/image/p2e-bg2.png);
  object-fit: contain;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.pol-img {
  height: 100%;
  width: 100%;
}
.pol-img.active p {
  font-size: 14px;
  color: var(--grenn-color);
}
.poly-grid {
  height: fit-content;
  display: grid;
}
.pol-img:nth-child(1) {
  left: 8rem;
}
.pol-img:nth-child(2) {
  left: 3rem;
}
.pol-img:nth-child(3) {
  left: 0rem;
}
.pol-img:nth-child(4) {
  left: 3rem;
}
.pol-img:nth-child(5) {
  left: 8rem;
}
.nft-img::after {
  content: "";
  position: absolute;
  width: 88%;
  height: 157px;
  left: 0;
  bottom: -30px;
  right: 0;
  background: #105b2a;
  filter: blur(28px);
  border-radius: 50%;
  transform: matrix(1, 0, 0, -1, 0, 0);
  margin: auto;
  z-index: 0;
}
.fancy-text {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),
    linear-gradient(
      250.62deg,
      #c6c4c4 0.95%,
      #d9d9d9 20.15%,
      rgba(255, 255, 255, 0.99) 55.47%,
      rgba(185, 185, 185, 0.63) 80.15%,
      rgba(231, 226, 226, 0.378023) 93.38%,
      rgba(217, 217, 217, 0) 113.24%
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 3px 0px 2px #b61f1f, -3px 0px 2px rgb(0 213 110 / 67%);
}

.text-shadow-green {
  text-shadow: 2px 1px 4px #05a33d;
}
.border-gradient {
  border: 0.5px solid #1df66a;
  border-image-slice: 1;
  border-width: 0.5px;
  border-image-source: linear-gradient(
    154.58deg,
    rgba(91, 86, 86, 0.296) -1.92%,
    rgb(203 203 203 / 47%) -1.91%,
    rgb(24 137 63 / 32%) 100.47%
  );
}
.swiper-pagination-bullet {
  background: rgba(217, 217, 217, 0.2) !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: #1df66a !important;
  width: 30px !important;
  border-radius: 2px !important;
}
.home-banner .slick-dots {
  bottom: 0;
  right: -60px;
  text-align: right;
  width: auto;
  height: fit-content;
  top: 0;
  margin: auto;
  transform: rotate(90deg);
  z-index: 2;
}
.home-banner .slick-dots li.slick-active {
  height: 4px !important;
  width: 60px !important;
}
.home-banner .slick-dots li {
  height: 4px !important;
  width: 60px !important;
  border-radius: 0 !important;
  transform: skew(34deg, 0deg);
}
.home-banner .slick-dots li button {
  width: 100%;
  height: 100%;
}
.slick-dots li button:before {
  content: "" !important;
}
.slick-dots li.slick-active {
  width: 21px !important;
  height: 8px !important;
  background: #1df66a;
  border-radius: 10px;
}
.slick-dots li {
  width: 8px !important;
  height: 8px !important;
  background: rgba(217, 217, 217, 0.2);
  border-radius: 10px;
}
.banner .slick-arrow {
  background-image: url("../src/ui/assets/image/arrow.png") !important;
  background-size: 100% 100% !important;
  height: 77px;
  width: 27px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 20;
  transform: rotate(0deg);
}
.banner .slick-arrow.slick-prev {
  transform: rotate(180deg);
}

.banner .slick-next:before,
.banner .slick-prev:before,
.slick-next:after,
.slick-next:before,
.slick-prev:before {
  content: "" !important;
  display: none;
}

.nft-card-slider .slick-arrow {
  background-image: url("../src/ui/assets/image/arrownew.svg") !important;
  background-size: 100% 100% !important;
  height: 77px;
  width: 27px;
  top: 0;
  bottom: 0;
  z-index: 20;
  margin: auto;
  transform: rotate(0deg);
}

.nft-card-slider .slick-arrow.slick-prev {
  transform: rotate(180deg);
}

.nft-card {
  background: url("../src/ui/assets/image/cardnft.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.nft-image {
  height: 266px;
}

.text-140xl {
  font-size: 140px;
  line-height: 1;
}
.slick-next {
  right: 0 !important;
}
.slick-prev {
  left: 0 !important;
}

/* landing page css */
.banner-text {
  font-family: "Russo One";
  font-style: normal;
  font-size: 90px;
  line-height: 132px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),
    linear-gradient(
      250.62deg,
      #6a6a6a 0.95%,
      #d9d9d9 20.15%,
      rgba(255, 255, 255, 0.99) 55.47%,
      rgba(108, 108, 108, 0.63) 80.15%,
      rgba(231, 226, 226, 0.378023) 93.38%,
      rgba(217, 217, 217, 0) 113.24%
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff7d;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 5px 4px 5px rgb(29 246 106 / 30%);
}
.text-32 {
  font-size: 32px;
}
.text-22 {
  font-size: 22px;
}
.text-26px {
  font-size: 26px;
}
.img-div {
  background: url("../src/ui/assets/image/shape.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 247px;
  height: 276px;
}

.para-bg {
  background: url("../src/ui/assets/image/parabg.svg");
}
.exp-btn {
  background: url("../src/ui/assets/image/exp-btn.png");
}

.bg-dark-green {
  background-color: var(--green-dard);
}
.bg-dark-green-grey {
  background-color: var(--green-dard-grey);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

.right-border::after {
  content: "";
  height: 129%;
  width: 2px;
  background: linear-gradient(45deg, #ffffff05, #08540f);
  position: absolute;
  top: -27px;
  right: 0;
}
.banner-ul li {
  background-image: url("../src/ui/assets/image/listshap.svg");
}
.h-82px {
  height: 82px;
}
.mt-132 {
  margin-top: 132px;
}
.rounded-20lg {
  border-radius: 20px;
}
.partner-slide .slick-slide > div {
  margin: 0 47px;
}
.team-build-slide .slick-slide > div {
  margin: 0 47px;
}
.team-build-slide .slick-dots li {
  width: 20px !important;
  height: 4px !important;
}
.team-build-slide .slick-dots li.slick-active {
  width: 50px !important;
  height: 4px !important;
}
.team-build-slide img {
  box-shadow: 3px 4px 11px 0px #1df66a33;
}

.team-build-slide::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  height: 224px;
  background: #1df66a;
  width: 6px;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
}

.team-build-slide .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 105px;
  bottom: 76px !important;
  width: auto;
}

.partner-slide .slick-track {
  align-items: center;
  display: flex;
}
.shadow-custom {
  box-shadow: 1px 1px 11px 6px #1df66a14;
}
.journey-card {
  background: #1d1d1d;
  border: 1px solid #597563;
}
.text-56 {
  font-size: 56px;
}

.journey-card::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -2px;
  border-top: 50px solid #1d1d1d;
  border-left: 50px solid var(--theme-color);
  width: 0;
  -webkit-transform: rotate(88deg);
  transform: rotate(91deg);
}
.journey-card::after {
  content: "";
  position: absolute;
  top: -11px;
  left: 23px;
  width: 1px;
  background: #597563;
  height: 69px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.journey-card:hover {
  background: linear-gradient(95.95deg, #28a855 -1.01%, #04b342 -1%, rgba(26, 112, 57, 0.38) 99.14%);
  -webkit-box-shadow: 10px 12px 11px 0px #1df66a4a;
  box-shadow: 10px 12px 11px 0px #1df66a4a;
}
.journey-card:hover::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -2px;
  border-top: 50px solid var(--theme-color);
  border-left: 50px solid #07a13e00;
  width: 0px;
  -webkit-transform: rotate(88deg);
  transform: rotate(270deg);
}
.journey-card:hover ul li {
  color: #000 !important;
}
.journey-card:hover h2 {
  color: #000 !important;
}
.journey-card:hover p {
  color: #000 !important;
}
.leading-62 {
  line-height: 62px !important;
}
.m-h-lg-393 {
  min-height: 393px;
}
.m-h-lg-253 {
  min-height: 253px;
}
@media (min-width: 1600px) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .nft-card {
    width: 282px;
  }
  .slick-dots {
    bottom: 189px !important;
  }

  .live-section .slick-slide > div {
    margin: 0 47px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  .text-140xl {
    font-size: 92px;
    line-height: 1;
  }
  .banner-text {
    font-size: 60px;
  }
}

@media (min-width: 1023px) and (max-width: 1368px) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  .team-build-slide .slick-slide > div {
    margin: 0 33px;
  }
}

@media (min-width: 320px) {
  .trending-poly rect {
    filter: grayscale(0) !important;
  }
  /* smartphones, iPhone, portrait 480x320 phones */
  .swiper-slide {
    height: 290px !important;
  }
  .swiper-3d {
    height: 380px !important;
  }

  .img-div2 {
    width: 100%;
    height: 100%;
  }
  .all-padding {
    width: 50px;
  }
  .login-avtarts.all-padding {
    width: 103px;
  }
  .text-40px {
    font-size: 18px;
  }
  .text-80px {
    font-size: 18px;
    /* letter-spacing: 2px; */
  }
  .or-btn-new {
    background-image: url("../src/ui/assets/image/btn-bg2.svg");
    height: 44px;
    background-size: 100% 100%;
  }
  .auth-btn {
    height: 44px;
    background-image: url("../src/ui/assets/image/orbtn.svg");
    background-size: 100% 100%;
  }
  .green-btn {
    height: 44px;
  }
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .trending-poly rect {
    filter: grayscale(1) !important;
  }
  .text-80px {
    font-size: 34px;
  }
  .text-40px {
    font-size: 20px;
  }
  .auth-btn {
    height: 56.23px;
    background-image: url("../src/ui/assets/image/btn-bg.svg");
  }
  .or-btn-new {
    background-image: url("../src/ui/assets/image/orbtn.svg");
    height: 56.23px;
  }
  .green-btn {
    height: 44px;
  }
  .avtar-banner {
    height: 56%;
  }
  .all-padding {
    width: 50px;
  }
  .login-avtarts.all-padding {
    width: 103px;
  }
  .pol-img:nth-child(1) {
    left: 6rem;
  }
  .pol-img:nth-child(2) {
    left: 2rem;
  }
  .pol-img:nth-child(4) {
    left: 2rem;
  }
  .pol-img:nth-child(5) {
    left: 5rem;
  }
}

@media (min-width: 772px) {
  .news-ltr-drop {
    background-image: url("../src/ui/assets/image/Bgnewsletter.png");
    background-size: 100% 100%;
  }
  .swiper-slide {
    height: 412px !important;
  }
  .swiper-3d {
    height: 500px !important;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

  .text-40px {
    font-size: 34px;
  }
  .text-80px {
    font-size: 48px;
  }
  .img-div2 {
    width: 409px;
    height: 470px;
  }
  .or-btn {
    background-image: url("../src/ui/assets/image/orbtn.svg") !important;
    background-size: 100% 100%;
  }
  .all-padding {
    width: 105px;
  }
  .login-avtarts.all-padding {
    width: 113px;
  }
  .pol-img:nth-child(1) {
    left: 6rem;
  }
  .pol-img:nth-child(2) {
    left: 2rem;
  }
  .pol-img:nth-child(3) {
    left: 0rem;
  }
  .pol-img:nth-child(4) {
    left: 2rem;
  }
  .pol-img:nth-child(5) {
    left: 6rem;
  }
  .avtar-banner {
    height: 64%;
  }
  .pol-img.active p {
    font-size: 14px;
  }
  .teams-slider.slick-initialized .slick-slide {
    padding: 0 75px;
  }
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .teams-slider .slick-prev {
    left: 5% !important;
  }
  .teams-slider .slick-next {
    right: 5% !important;
  }
  .text-80px {
    font-size: 56px;
  }
  .all-padding {
    width: 67px;
  }
  .login-avtarts.all-padding {
    width: 82px;
  }
  .pol-img:nth-child(1) {
    left: 6rem;
  }
  .pol-img:nth-child(2) {
    left: 2rem;
  }
  .pol-img:nth-child(3) {
    left: 0rem;
  }
  .pol-img:nth-child(4) {
    left: 2rem;
  }
  .pol-img:nth-child(5) {
    left: 6rem;
  }
  .avtar-banner {
    height: 70%;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .all-padding {
    width: 105px;
  }
  .login-avtarts.all-padding {
    width: 105px;
  }
  .pol-img:nth-child(1) {
    left: 6rem;
  }
  .pol-img:nth-child(2) {
    left: 2rem;
  }
  .pol-img:nth-child(3) {
    left: 0rem;
  }
  .pol-img:nth-child(4) {
    left: 2rem;
  }
  .pol-img:nth-child(5) {
    left: 6rem;
  }
  .avtar-banner {
    height: 57%;
  }
  .pol-img.active p {
    font-size: 16px;
  }
}
@media (min-width: 1382px) {
  /* hi-res laptops and desktops */
  .green-btn {
    height: 57px;
  }
  .all-padding {
    width: 105px;
  }
  .login-avtarts.all-padding {
    width: 105px;
  }
  .pol-img:nth-child(1) {
    left: 7rem;
  }
  .pol-img:nth-child(2) {
    left: 2rem;
  }
  .pol-img:nth-child(3) {
    left: -1rem;
  }
  .pol-img:nth-child(4) {
    left: 2rem;
  }
  .pol-img:nth-child(5) {
    left: 7rem;
  }
}
@media (min-width: 1482px) {
  /* hi-res laptops and desktops */
  .all-padding {
    width: 95px;
  }
  .pol-img.active p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .login-avtarts.all-padding {
    width: 105px;
  }
  .pol-img:nth-child(1) {
    left: 6rem;
  }
  .pol-img:nth-child(2) {
    left: 2rem;
  }
  .pol-img:nth-child(3) {
    left: -1rem;
  }
  .pol-img:nth-child(4) {
    left: 2rem;
  }
  .pol-img:nth-child(5) {
    left: 6rem;
  }
}
@media (min-width: 1582px) {
  /* hi-res laptops and desktops */
  .pol-img.active p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-80px {
    font-size: 80px;
  }
  .all-padding {
    width: 105px;
  }
  .login-avtarts.all-padding {
    width: 122px;
  }
  .pol-img:nth-child(1) {
    left: 8rem;
  }
  .pol-img:nth-child(2) {
    left: 4rem;
  }
  .pol-img:nth-child(3) {
    left: 0rem;
  }
  .pol-img:nth-child(4) {
    left: 4rem;
  }
  .pol-img:nth-child(5) {
    left: 8rem;
  }
  .teams-slider.slick-initialized .slick-slide {
    padding: 0 75px;
  }
}

@media (min-width: 1900px) {
  .pol-img.active p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .login-avtarts.all-padding {
    width: 140px;
  }
  .pol-img:nth-child(1) {
    left: 9rem;
  }
  .pol-img:nth-child(2) {
    left: 5rem;
  }
  .pol-img:nth-child(3) {
    left: 1rem;
  }
  .pol-img:nth-child(4) {
    left: 5rem;
  }
  .pol-img:nth-child(5) {
    left: 9rem;
  }
}

@media (max-width: 767px) {
  .text-140xl {
    font-size: 52px;
    line-height: 1;
  }
  .banner-bg {
    background-size: 110%;
    background-position: 30% -7%;
  }

  .banner .slick-arrow {
    height: 28px;
    width: 13px;
  }
  .slick-dots li.slick-active {
    height: 5px !important;
  }
  .slick-dots li {
    height: 5px !important;
  }
  .drop-section {
    background-size: contain;
  }
  .nft-card {
    width: 282px;
  }
  .banner-text {
    font-size: 24px;
    line-height: 30px;
  }
  .plygn-img {
    display: none !important;
  }
  .team-build-slide .slick-slide > div {
    margin: 0 15px;
  }
  .team-build-slide::after {
    height: 138px;
    width: 1px;
    margin: revert;
    margin-top: 18px;
  }
  .slider-text p {
    height: 80px;
    overflow: hidden;
  }
  .team-build-slide .slick-dots {
    bottom: 15px !important;
    margin-left: 16px !important;
  }
  .join-card .img-div {
    width: 122px;
    height: 136px;
  }
  .text-22 {
    font-size: 12px !important;
  }
  .bg-hidden {
    background-color: transparent !important;
  }
  .right-border::after {
    display: none;
  }
  .text-26px {
    font-size: 14px;
  }
  .banner-ul .text-26px {
    font-size: 12px !important;
  }
  .mt-132 {
    margin-top: 40px;
  }
  .banner-ul li {
    width: max-content;
    height: 40px;
    min-width: 210px;
  }
  .text-56 {
    font-size: 16px;
  }
  .jurney-card .text-56 {
    font-size: 40px;
  }
  .m-h-lg-253,
  .m-h-lg-393 {
    min-height: auto;
  }
  .md-h-274 {
    height: 183px;
  }
  .md-h-454 {
    height: auto;
  }
}

.container-1978 {
  margin: 0 auto;
  max-width: 1920px;
}

.user-badges-active,
.user-badges-active ~ p {
  color: var(--grenn-color);
  background-color: rgba(29, 246, 106, 0.1);
  width: 100%;
  height: 100%;
}

.clip-image-polygon {
  clip-path: polygon(0 24px, 45px 0, 89px 24px, 89px 71px, 44px 95px, 0 71px);
  overflow: hidden;
  background: transparent;
  width: 93px;
  height: 98px;
  position: absolute;
  top: 9px;
  left: 8px;
}

.scroll-flex::-webkit-scrollbar-track {
  border-radius: 10px;
  border: none;
}

.scroll-flex::-webkit-scrollbar {
  height: 7px;
  border: none;
  border-radius: 15px;
  background-color: var(--theme-color);
}

.scroll-flex::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
  border: none;
}

.hovernftuser:hover .rect-green {
  fill: var(--grenn-color);
}

.hovernftuserbtn.rect-green {
  fill: var(--grenn-color);
}
.hovernftuserbtn ~ p {
  color: red;
}
.table-grid-3 {
  grid-template-columns: 1fr 6.2fr 2fr;
}
.max-w-283 {
  max-width: 283px;
}
.activity-line {
  height: 0.35px;
  background-color: #6b6b6b;
}
.navbar-width {
  width: 100%;
  height: 80px;
}
.width-1920 {
  max-width: 1920px;
  width: 90%;
  margin: 0 auto;
  /* background-color: aqua; */
}

.width-90per {
  width: 90%;
}
.height-798 {
  height: 806px;
}
.top-73 {
  top: 73px;
}
.bg-position-xy10 {
  background-position: 10% 10%;
}
.video-frame {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.btnSub {
  width: 161px;
  height: 45px;
}

.h-37_38 {
  /* height: 37.64px; */
  padding: 11;
  margin-top: -0.4px;
  max-width: 350px;
  width: 100%;
  height: 100%;
}
.h-37_38 input {
  width: 100%;
  height: 100%;
  padding: 11px;
}

@media (max-width: 768px) {
  .h-37_38 {
    max-width: 300px;
    width: 100%;
  }
}
.parallax {
  overflow: hidden;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}
.bg-buildTeam {
  background-image: url(ui/assets/image/bg-buildteam.png);
  background-size: cover;
  background-position: top;
}
.btn-cut-corner {
  background: linear-gradient(-135deg, transparent 5px, var(--grenn-color) 0) top,
    linear-gradient(45deg, transparent 5px, var(--grenn-color) 0) bottom;
  background-size: 100% 60%;
  background-repeat: no-repeat;
}
.btn-cut-corner:hover,
.btn-cut-corner-2:hover {
  filter: drop-shadow(0px 4px 8px rgba(0, 153, 54, 0.6));
}
.btn-cut-corner-2 {
  background: linear-gradient(-135deg, transparent 3px, var(--grenn-color) 0) top,
    linear-gradient(45deg, transparent 3px, var(--grenn-color) 0) bottom;
  background-size: 100% 60%;
  background-repeat: no-repeat;
}
.grayscale-new rect {
  filter: grayscale(1);
}
.grayscale-new:hover rect {
  filter: grayscale(0);
}
.nftCardSize {
  /* width: 280px; */
  /* height: 480px; */
  width: 200px;
  height: 400px;
  transition: 1s all;
}

.nftCardB {
  width: 100%;
  height: 100%;
  clip-path: polygon(
    0 20px,
    20px 0,
    calc(100% - 20px) 0,
    100% 20px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0 calc(100% - 10px)
  );
  background: linear-gradient(to bottom, black, black, rgba(255, 255, 255, 0.233), green);
  display: flex;
  justify-content: center;
  align-items: center;
}
.nftCardS {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-color: black;
  clip-path: polygon(
    0 18px,
    18px 0,
    calc(100% - 18px) 0,
    100% 18px,
    100% calc(100% - 9px),
    calc(100% - 9px) 100%,
    9px 100%,
    0 calc(100% - 9px)
  );
}
.grid-two {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.hexagonB {
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  /* overflow: ; */
  position: relative;
  background-color: #04b341a9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hexagonS {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  /* overflow: ; */
  position: relative;
}
.duration-2s {
  transition: 2s all ease-in;
}

.octagonB {
  width: 100%;
  height: 100%;
  background-color: #05a33d;
  clip-path: polygon(50% 0, 85% 15%, 100% 50%, 85% 85%, 50% 100%, 15% 85%, 0 50%, 15% 15%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.octagonS {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: black;
  clip-path: polygon(50% 0, 85% 15%, 100% 50%, 85% 85%, 50% 100%, 15% 85%, 0 50%, 15% 15%);
}

.box-shadow-lg {
  box-shadow: 10px 10px 10px 10px black;
}

.height-440 {
  height: 560px;
}
@media screen and (max-width: 1238px) {
  .height-440 {
    height: 600px;
  }
}

@media screen and (max-width: 726px) {
  .height-440 {
    height: 360px;
    margin-bottom: 50px;
  }
  .o,
  .freemint-land-card {
    perspective: 1500px;
  }
}

@media screen and (max-width: 546px) {
  .freemint-land-card {
    transition: 700ms all ease-out !important;
  }
}

.modal {
  pointer-events: none;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  opacity: 0;
  z-index: 999;
  --tw-bg-opacity: 0.4;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform, opacity;
  overflow-y: hidden;
  overscroll-behavior: contain;
}

.modal-open,
.modal:target,
.modal-toggle:checked + .modal {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}
.modal-toggle:checked + .modal {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}
:where(.modal) {
  align-items: center;
}

.modal-box {
  /* padding: 1.5rem; */
  transition-duration: 0.2s;
  width: 91.666667%;
  max-width: 32rem;
  box-shadow: 0 25px 50px -12px #00000040;
  overflow-y: auto;
  overscroll-behavior: contain;
}
.modal-toggle {
  position: fixed;
  height: 0;
  width: 0;
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
}

/* freemint css */

.freemint-land-card {
  border: 1px solid green;
  width: 100%;
  height: 360px;
  background-color: #000;
}
.center-card {
  /* top: 0; */
  z-index: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: all 2s;
  margin-top: -10%;
}
/* .center-card{
    height: 440px;
    transform: translateY(50px);
    transition: all 2s;
    width: 240px;
} */
.card-freemint {
  position: absolute;
  transition: all 2s;
  width: 28%;
}
.card-freemint.first {
  left: 0;
  right: auto;
  z-index: 4;
  top: auto;
  transition: all 2s;
}
.card-freemint.second {
  right: 0;
  z-index: 3;
  transition: all 2s;
}
.card-freemint.third {
  right: 0;
  z-index: 2;
  transition: all 2s;
}

.card-freemint.active {
  /* top: 15%; */
  left: 50%;
  transform: translate(-50%, 30%) scale(1.2);
  z-index: 80;
  transition: all 2s;
}
.overflow-wrap {
  overflow-wrap: anywhere;
}
.feed-slider .slick-slide > div {
  padding: 0 33px !important;
}
@media screen and (max-width: 1271px) {
  .freemint-land-card {
    height: 275px;
  }
  .feed-slider .slick-slide > div {
    padding: 0 22px !important;
  }
}
@media screen and (max-width: 1087px) {
  .freemint-land-card {
    height: 220px;
  }
}

@media screen and (max-width: 726px) {
  .freemint-land-card {
    height: 257px;
  }
  .journey-back.left {
    background-image: url(ui/assets/image/journey-right.png) !important;
  }
  
}
@media screen and (max-width: 561px) {
  .freemint-land-card {
    height: 170px;
  }
}
.trending-polygon {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);

  background-color: #04b342;
}
.homeDetails-text-bg {
  min-height: 120px;
  /* max-width: 400px; */
  padding: 5px 40px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.homeDetails-text-bg.left {
  justify-content: right;
  background-position: right;
  background-size: 100% 100%;
  background-image: url(ui/assets/image/rect-left.png);
}
.homeDetails-text-bg.right {
  justify-content: left;
  background-position: left;
  background-size: 100% 100%;
  background-image: url(ui/assets/image/rect-right.png);
}
.vid-grid::after{
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg,black, #000000b3,#00000000,#00000000, #00000000);
  bottom: -3px;
  left: 0;
  /* border-radius: 49%; */
  z-index: 2;


}
.trendingP,
.trending-dropShadow {
}

.trendingP {
  filter: grayscale(100%);
}
.trending-dropShadow:hover {
  filter: drop-shadow(0px 0px 15px green);
  /* box-shadow: 0 0 10px 10px green; */
}
.trendingP:hover {
  filter: grayscale(0);
}
.bg-custom-trueS {
  /* background-image: url(ui/assets/image/truesocial.png); */
  /* background-size: cover; */
}

/* .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}



.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.swiper-slide {
  background-position: center;
  background-size: cover;
  height: 412px;
  background-repeat: no-repeat !important;
  filter: grayscale(1);
  clip-path: polygon(96% 0, 132% 46%, 408% 125%, 16% 133%, 0 147%, 0 -35%);
  overflow: hidden;
  background-size: 309px;
  -webkit-box-reflect: below 0px -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1) 17%, rgba(
          0,
          0,
          0,
          0
        )
        34%, rgba(0, 0, 0, 0));
}





/* ///////////////////////////////////// */



/* .swiper-slide.swiper-slide-visible{
  transform: translate3d(0px, 0px, -200.115px) rotateX(0deg) rotateY(290deg) scale(1) !important;
}
.swiper-slide.swiper-slide-duplicate.swiper-slide-visible{
  transform: translate3d(0px, 0px, -200.057px) rotateX(0deg) rotateY(70deg) scale(1) !important;
}
.swiper-slide.swiper-slide-duplicate.swiper-slide-visible.swiper-slide-active{
  transform: translate3d(0px, 0px, -0.0573723px) rotateX(0deg) rotateY(-0.0286862deg) scale(1) !important;
}
.swiper-slide.swiper-slide-duplicate.swiper-slide-visible.swiper-slide-prev{
  transform: translate3d(0px, 0px, -100.057px) rotateX(0deg) rotateY(50.0287deg) scale(1) !important;
}
.swiper-slide.swiper-slide-visible.swiper-slide-prev{
  transform: translate3d(0px, 0px, -100.057px) rotateX(0deg) rotateY(50.0287deg) scale(1) !important;
}
.swiper-slide.swiper-slide-visible.swiper-slide-next{
  transform: translate3d(0px, 0px, -100.057px) rotateX(0deg) rotateY(-50.0287deg) scale(1) !important;
}
.swiper-slide.swiper-slide-visible.swiper-slide-active{
  transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
} */













.swiper-slide-active .feature-tab {
  transform: translateY(0);
}
.feature-tab{
  /* left: 50%; */
  /* transform: translate(-50%,0); */
}
.img-top-cut{
  clip-path: polygon(91% 0, 158% 46%, 408% 125%, 16% 133%, 0 147%, 0 -35%);
}
.swiper-slide-active {
  filter: grayscale(0);
}
.swiper-slide-active .top-milnew {
  display: block;
}
.swiper-slide:hover {
  filter: grayscale(0);
}
.bg-grad-filter {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(170.14deg, rgba(0, 0, 0, 0.16) 10.29%, rgba(179, 179, 179, 0.064) 116.09%);
  box-shadow: inset 2.503px -2.503px 24.9055px rgba(214, 214, 214, 0.17),
    inset -3.75243px 3.75243px 3.75243px rgba(255, 255, 255, 0.39);
  backdrop-filter: blur(12.4527px);
}
.feed-slider .slick-list {
  margin: 0 -33px !important;
}

@media screen and (max-width: 736px) {
  .feed-slider .slick-list {
    width: 100%;
    margin: 0 0 !important;
  }
  .journey-back.left {
    justify-content: right;
    /* background-position: right; */
    /* background-size: auto; */
    background-image: url(ui/assets/image/journey-left.png);
  }
  .journey-back.right {
    justify-content: right;
    /* background-position: left; */
    /* background-size: auto; */
    background-image: url(ui/assets/image/journey-right.png);
  }
  .journey-back {
    /* min-height: 172px; */
    padding-left: 25%;
    padding-top: 2%;
    padding-bottom: 2%;
    /* background-size: 100% 100%; */
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    /* height: fit-content; */
  }
  .text-white-border{
    -webkit-text-stroke-width: 0.5px !important;
  }
  .text-green-border{
    -webkit-text-stroke-width: 0.5px !important;
  }
}

.partner-slid .slick-track {
  display: flex;
  align-items: center;
}

.trending-poly rect {
  filter: grayscale(1);
  cursor: pointer;
  border: 1px solid #04b342;
}

.trending-poly rect:hover {
  filter: grayscale(0) 
  /* drop-shadow(0 0 16px #1df66a)  */
  drop-shadow(-1px -1px 0px #1df66a) 
  drop-shadow(2px -1px 0px #1df66a) 
  drop-shadow(2px 2px 0px #1df66a)
  drop-shadow(-1px 2px 0px #1df66a) !important;
}
.trending-poly a:hover{
  filter:drop-shadow(0 0 16px #1df66a)
}

svg defs image:hover{
  filter:drop-shadow(-1px -1px 0px #1df66a) 
  drop-shadow(2px -1px 0px #1df66a) 
  drop-shadow(2px 2px 0px #1df66a)
  drop-shadow(-1px 2px 0px #1df66a) !important;
}



/* .journey-back.left::after{
  content: '';
  width: 4px;
  height: 100%;
  position: absolute;
  right: -3%;
  top: -12%;
  background: #1df66a;
  transform: rotateZ(150deg);

}

.journey-back.right::after{
  content: '';
  width: 4px;
  height: 100%;
  position: absolute;
  left: -3%;
  top: -12%;
  background: #1df66a;
  transform: rotateZ(-150deg);

} */

.feed-box {
  background-image: url(ui/assets/image/feedback.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.feed-slider .slick-dots {
  position: relative;
  margin-bottom: 39px;
}
.mil-top {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(170.14deg, rgba(0, 0, 0, 0.16) 10.29%, rgba(179, 179, 179, 0.064) 116.09%); */
  /* box-shadow: inset 2.503px -2.503px 24.9055px rgb(214 214 214 / 17%), inset -3.75243px 3.75243px 3.75243px rgb(255 255 255 / 39%); */
  background: var(--grenn-color);
  backdrop-filter: blur(12.4527px);
  border: 3px solid #1df66a;
  width: fit-content;
}
.overflow-homebanner {
  overflow: hidden;
}
@media screen and (max-width: 736px) {
  .overflow-homebanner {
    overflow: inherit;
  }
}
.swiper-button-next, .swiper-button-prev{
  color: #04b342 !important;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next{
  left: 0 !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev{
  right: 0 !important;
}
.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 25px !important;
}

.text-white-border{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}
.text-green-border{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--grenn-color);
  color: #1DF66A99;
}
.border-green-text{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--grenn-color);
  color: transparent;
}
.true-bg::after{
  content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, black, transparent, black);
    z-index: 0;

}

.btn-hov:hover{
  /* filter: drop-shadow(2px 4px 6px #1df66a); */
  text-shadow: 0px 0px 16px #1df66a;
}
.games-hex img{
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  margin-bottom: 20px;
  height: 95px;
  width: 100px;
}
.games-hex{
  display:-webkit-inline-box
}
.mob-hex img{
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  /* width: 140px; */
  width: 100%;

  /* width: var(--s);
  margin: var(--m) var(--m-h);
  height: calc(var(--s)*var(--r));
  display: inline-block;
  font-size:initial; */
  /* background:red; */
  /* clip-path: polygon(var(--hc) 0, calc(100% - var(--hc)) 0,100% var(--vc),100% calc(100% - var(--vc)), calc(100% - var(--hc)) 100%,var(--hc) 100%,0 calc(100% - var(--vc)),0 var(--vc)); */
  /* margin-bottom: calc(var(--m) - var(--vc)); */
  /* margin: 16px; */
}
.mob-hex div{
  /* width: var(--s); */
  /* width: 25%;
  margin: var(--m) var(--m-h);
  height: calc(var(--s)*var(--r));
  display: inline-block;
  font-size:initial; margin-bottom: calc(var(--m) - var(--vc));
  margin-right: 15%;
  margin-left: 0px; */
  /* width: var(--s); */
  margin: var(--mv) var(--mh);
  /* height: calc(var(--s)*var(--r));  */
  height: 23%;
  display: inline-block;
  font-size:initial;
  margin-bottom: calc(var(--mv) - var(--vc));
  margin-right: 7%;
}

.abs-div{
  position: absolute;
  right: -10%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  /* transform: translateY(-50%); */
  top: 20%;
}
.mob-hex{
  position: relative;
}
.hellodiv{
    /* display:flex !important;
    --s: 190px;
    --r: 1;
    --hc: 0px;
    --vc: 30%;
    --m: 2px;
    --m-h: calc(var(--m) + (var(--s) - 2*var(--hc))/2);
    --f: calc(2*var(--s) + 4*var(--m) - 2*var(--vc) - 2px); */
    display: flex;
  grid-template-columns: repeat(auto-fit,calc(var(--s) + 2*var(--mh)));
  justify-content:center;
  --s: 75px; /* size */
  --r: 1; /* ratio */
  
  /* clip-path */
  --h: 0.25;  
  --v: 0.35; 
  --hc:calc(clamp(0,var(--h),0.5) * var(--s)) ;
  --vc:calc(clamp(0,var(--v),0.5) * var(--s) * var(--r)); 
  
  /*margin */
  --mv: 4px; /* vertical */
  --mh: calc(var(--mv) + (var(--s) - 2*var(--hc))/2); /* horizontal */
  /* for the float*/
  --f: calc(2*var(--s)*var(--r) + 4*var(--mv)  - 2*var(--vc) - 2px);
}
.mob-hex::before {
  content: "";
  /* width: calc(var(--s)/2 + var(--mh)); */
  width: 24%;
  float: left;
  height: 100%;
  shape-outside: repeating-linear-gradient( #0000 0 calc(var(--f) - 2px), #000 0 var(--f));
}